<div class="col-11 col-sm-5 col-md-5 col-lg-3 col-xl-3 m-auto bloque-formularios" *ngIf="token">
    <div class="row">
      <h1 class="title">Gracias por realizar <br/> nuestra encuesta!</h1>
        <div class="form-login">
            <div class="form-group">
                <label for="cedula" class="lbl-form">Cuales son las lecciones aprendidas del Dia del Reinicio Toyota?</label>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox"  id="exampleRadiosq11"  disabled>
                    <label class="form-check-label" for="exampleRadiosq11">
                        Trabajar de la forma más rápida posible, dejando de lado los detalles y la voz del cliente porque lo que nos importa es producir más. 
                    </label>
                  </div>
                  
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox"  id="exampleRadiosq12" checked disabled>
                    <label class="form-check-label" for="exampleRadiosq12">
                        Aumentar nuestra sensibilidad para escuchar la voz del cliente y los cambios en el mercado, reconociendo inmediatamente cualquier diferencia entre nuestro punto de vista y el del cliente. 
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox"  id="exampleRadiosq13"  checked disabled>
                    <label class="form-check-label" for="exampleRadiosq13">
                        Además de cumplir con todas las leyes y regulaciones, ofrecer un nivel aún más alto de seguridad y confianza a nuestros clientes. 
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox"  id="exampleRadiosq14"  checked disabled>
                    <label class="form-check-label" for="exampleRadiosq14">
                        Mantener una comunicación fluida, tanto interna como externa, entregando a tiempo la información que los clientes necesitan. 
                    </label>
                  </div>
            </div>
            <hr/>
            <div class="form-group">
                <label for="cedula" class="lbl-form">Qué es el día del Reinicio?</label>
                <div class="form-check">
                    <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadiosq21" value="a" disabled>
                    <label class="form-check-label" for="exampleRadiosq21">
                        Fue un problema que paso en otro continente y que no nos debe preocupar porque en nuestro país no hemos tenido ningún problema.
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadiosq22" value="b" checked disabled>
                    <label class="form-check-label" for="exampleRadiosq22">
                        Es un día para no olvidarnos, aprender de las lecciones del pasado y transmitir las enseñanzas a las nuevas generaciones. 
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadiosq23" value="c" disabled>
                    <label class="form-check-label" for="exampleRadiosq23">
                        Reafirmar el compromiso de que Toyota está primero, y continuar con nuestros procesos que garantizan seguridad y calidad.  
                    </label>
                  </div>
            </div>           
        </div>
    </div>   
</div>