<div class="col-11 col-sm-5 col-md-5 col-lg-3 col-xl-3 m-auto bloque-formularios">
    <div class="row">
        <h2 class="title-login">Inicio de Sesión</h2>
        <ng-template #loading>
            <div class="d-flex justify-content-center">
                <div class="spinner-border" role="status">                      
                </div>
            </div>
        </ng-template>
        <form [formGroup]="formulario" *ngIf="!loadingService; else loading" class="form-login">
            <div class="form-group">
                <label for="cedula" class="lbl-form">Carnet de Identidad</label>
                <input type="text" formControlName="ci" class="form-control inp" id="cedula" >
            </div>           
            <button (click)="submitForm()" class="btn-send">Iniciar sesión</button>  
        </form>
    </div>   
</div>