import { Component, NgZone } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs';
import Swal from 'sweetalert2';
import { EncuestaService } from '../services/encuesta.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  formulario: FormGroup;
  loadingService: boolean = false;

  constructor(private usuarioServicios: EncuestaService,private router: Router, private ngZone:NgZone) {
    this.loadingService = false;
    this.formulario = new FormGroup({
      ci: new FormControl('', Validators.required)
    });
  }

  ngOnInit(): void {
  }

  submitForm() {

    if(this.formulario.controls['ci'].value !="") {
      const data = {
        ci:this.formulario.controls['ci'].value
      }
      this.loadingService = true;
      this.usuarioServicios.login(data).pipe(first()).subscribe(
          (res:any) => {
              localStorage.setItem('reinicioTKT', JSON.stringify(res));
              this.loadingService = false;
              this.router.navigate(['/encuesta']).then(() => {window.location.reload();});
              
          }, (error:any) => {
            console.log(error)
            this.loadingService = false;
            Swal.fire({
              icon: "error",
              title: "Datos incorrectos",
              text: "Tu cuenta no está registrada",
              showConfirmButton: false,
              timer: 1500
            });
            this.formulario.reset();
          }
      )
    } else {
      Swal.fire({
        icon: "warning",
        title: "Campos vacíos, por favor ingrese sus datos",
        showConfirmButton: false,
        timer: 1500
      });
      this.formulario.reset();
    }
    
  }
}
