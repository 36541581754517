import { Component } from '@angular/core';
import { EncuestaService } from '../services/encuesta.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-gracias',
  templateUrl: './gracias.component.html',
  styleUrls: ['./gracias.component.css']
})
export class GraciasComponent {

  token:any;

  constructor(private router: Router,private usuarioServicios: EncuestaService) {

    const userLocal = localStorage.getItem('reinicioTKT');
    if (userLocal) {
      this.token = JSON.parse(userLocal).token;
      setTimeout(() => {
        localStorage.clear();
        this.router.navigate(['/']).then(() => {window.location.reload();});
      }, 10000);
    } else {
      this.token = null;
    }
    
  }

}
