import { Component, NgZone } from '@angular/core';
import Swal from 'sweetalert2';
import { EncuestaService } from '../services/encuesta.service';
import { Router } from '@angular/router';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs';

@Component({
  selector: 'app-encuesta',
  templateUrl: './encuesta.component.html',
  styleUrls: ['./encuesta.component.css']
})
export class EncuestaComponent {
  form: FormGroup;
    nombre:any;
    loadingService: boolean = false;
    q1:any=[];
    q2:any;
    token:any;
    isrealizada:any;
    q1correcta:any;
    q2correcta:any;
    q1Inicia:any;
    q2Inicia:any;
    val1:any;
    val2:any;
    val3:any;
    val4:any;
    opciones:any;

    DataCheck: Array<any> = [
      { name: 'Trabajar de la forma más rápida posible, dejando de lado los detalles y la voz del cliente porque lo que nos importa es producir más. ', value: '1' },
      { name: 'Aumentar nuestra sensibilidad para escuchar la voz del cliente y los cambios en el mercado, reconociendo inmediatamente cualquier diferencia entre nuestro punto de vista y el del cliente.', value: '2' },
      { name: 'Además de cumplir con todas las leyes y regulaciones, ofrecer un nivel aún más alto de seguridad y confianza a nuestros clientes.', value: '3' },
      { name: 'Mantener una comunicación fluida, tanto interna como externa, entregando a tiempo la información que los clientes necesitan.', value: '4' },
    ];

    constructor(private fb: FormBuilder, private usuarioServicios: EncuestaService,private router: Router, private ngZone:NgZone) {
      this.loadingService = false;
      this.form = this.fb.group({
        checkArray: this.fb.array([], [Validators.required]),
      });

      const userLocal = localStorage.getItem('reinicioTKT');
      if (userLocal) {
        this.nombre = JSON.parse(userLocal).user.fullname;
        this.token = JSON.parse(userLocal).token;
        this.isrealizada = JSON.parse(userLocal).encuesta.realizado;
        if(this.isrealizada == 1 ){
          Swal.fire({
            icon: "warning",
            title: "Aviso importante",
            text: "Usted ya tiene registrada la encuesta.",
            showConfirmButton: false,
            timer: 2500
          });

          setTimeout(() => {
            this.router.navigate(['/']).then(() => {window.location.reload();});
            localStorage.clear();
          }, 3000);
        }
      } else {
        this.nombre = "No exite";
        this.token = null;
      }
      
    }
  
    ngOnInit(): void {
    }

    onCheckboxChange(e: any, ind:any) {
      
      if (e.target.checked) {
        this.q1Inicia = true;
        this.q1.push(e.target.value);
        if (e.target.value == 1) {
          document.getElementById("check0")?.classList.add("newcheck")!; 
          this.q1correcta = false;
          this.val1 = true;
        } else {
          if(e.target.value == 2 ){
            this.val2 = true;
          }
          if(e.target.value == 3){
            this.val3 = true;
          }
          if(e.target.value == 4 ){
            this.val4 = true;
          }
          console.log(this.val2+"   "+this.val3+"   "+this.val4)
          if(e.target.value == 2 && this.val3 ==true && this.val4 ==true){
            this.q1correcta = true;
          }
          if(e.target.value == 3 && this.val2 ==true && this.val4 ==true){
            this.q1correcta = true;
          }
          if(e.target.value == 4 && this.val3 ==true && this.val2 ==true){
            this.q1correcta = true;
          }
          
          document.getElementById("check1")?.classList.add("correctcheck")!; 
          document.getElementById("check2")?.classList.add("correctcheck")!;
          document.getElementById("check3")?.classList.add("correctcheck")!;
          //this.q1countCorrecta++;
        }
        
      } else {
        console.log(this.val2+"   "+this.val3+"   "+this.val4)
        if (ind == 0) {
          this.val1 = false;
          if (this.validar()) {
            this.q1correcta = true;
          }
        }
        if(ind == 1){
          this.val2 = false;
          this.q1correcta = false;
        }
        if(ind == 2){
          this.val3 = false;
          this.q1correcta = false;
        }
        if(ind == 3){
          this.val4 = false;
          this.q1correcta = false;
        }

        for (let rec = 0; rec < this.q1.length; rec++) {
          if (this.q1[rec] == e.target.value) {
            this.q1.splice(rec,1);
            return;
          }
        }
        
      };
    }


    validar(){
      if(this.val1 ==false && this.val2 ==true && this.val3 ==true && this.val4 ==true){
        return true;
      } else {
        return false;
      }
    }
    onRadioChange(e:any, id:any){
      this.q2Inicia = true;
      if (id==6) {
        this.q2correcta = true;
      } else {
        this.q2correcta = false;
      }
    }

    submitForm() {
      
      if(this.q1.length > 0 && this.q2!=null) {
        const data = [];
        let validador = false;
        for (let cont = 0; cont < this.q1.length; cont++) {
          if(this.q1[cont] != 1){
            validador = false;
          } else {
            validador =true;
          }
          data.push(
            {
              pregunta_id:1,
              opcion_pregunta_id:this.q1[cont]
            }
          );
          
        }
        if(this.q1correcta ==true && this.q2correcta==true) {
            data.push({
              pregunta_id:2,
              opcion_pregunta_id:this.q2
            });
            this.loadingService = true;
            this.usuarioServicios.registrar(data).pipe(first()).subscribe(
                (res:any) => {
                    this.loadingService = false;
                    Swal.fire({
                      icon: "success",
                      title: "Enviada",
                      text: "Gracias por realizar nuestra encuesta.",
                      showConfirmButton: false,
                      timer: 3000
                    });
                    setTimeout(() => {
                      localStorage.clear();
                      this.router.navigate(['/']).then(() => {window.location.reload();});
                    }, 3500);
                    
                }, (error:any) => {
                  console.log(error)
                  this.loadingService = false;
                  Swal.fire({
                    icon: "error",
                    title: "No se logró registrar",
                    text: "Por favor intente en unos minutos el registro de su encuesta.",
                    showConfirmButton: false,
                    timer: 3000
                  });
                }
            )
          } else {
            console.log(this.q1);
            Swal.fire({
              icon: "warning",
              title: "Respuestas incorrectas",
              text: "Por favor revisa tus respuestas antes de enviar tu encuesta.",
              showConfirmButton: false,
              timer: 3000
            });
          }
      } else {
        console.log(this.q1);
        Swal.fire({
          icon: "warning",
          title: "Tienes preguntas pendientes",
          text: "Por favor responda las 2 preguntas.",
          showConfirmButton: false,
          timer: 3000
        });
      }
      
    }
}
