<div class="col-11 col-sm-5 col-md-5 col-lg-3 col-xl-3 m-auto bloque-formularios" *ngIf="token">
    <div class="row">
        <h2 class="title-login">Nombre: <span>{{nombre}}</span></h2>
        <ng-template #loading>
            <div class="d-flex justify-content-center">
                <div class="spinner-border" role="status">                      
                </div>
            </div>
        </ng-template>
        <div *ngIf="!loadingService; else loading" class="form-login">
            <div class="form-group">
                <label for="cedula" class="lbl-form">1. ¿Cuales son las lecciones aprendidas del Dia del Reinicio Toyota? <span class="etiqueta">Selección múltiple</span></label>
                
                <div *ngFor="let dataq of DataCheck; let i=index">
                    <div class="form-check">
                        <input class="form-check-input mycheck{{i}}" type="checkbox" [value]="dataq.value" id="check{{i}}" (change)="onCheckboxChange($event, i)"/>
                        <label class="form-check-label" for="check{{i}}">
                            {{dataq.name}}
                        </label>
                            
                    </div>
                </div>
                <div *ngIf="q1Inicia">
                    <div class="correct" *ngIf="q1correcta">Respuesta correcta</div>
                    <div class="opt" *ngIf="opciones">Faltan opciones</div>
                    <div class="incorrect" *ngIf="!q1correcta">Respuesta incorrecta</div>
                </div>
                
            </div>
            <hr/>
            <div class="form-group">
                <label for="cedula" class="lbl-form">2. ¿Qué es el día del Reinicio?</label>
                <div class="form-check">
                    <input class="form-check-input radiocheck5" type="radio" name="exampleRadios" id="exampleRadiosq21" value="5" [(ngModel)]="q2" (change)="onRadioChange($event, 5)">
                    <label class="form-check-label" for="exampleRadiosq21">
                        Fue un problema que paso en otro continente y que no nos debe preocupar porque en nuestro país no hemos tenido ningún problema.
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input radiocheck6" type="radio" name="exampleRadios" id="exampleRadiosq22" value="6" [(ngModel)]="q2" (change)="onRadioChange($event, 6)">
                    <label class="form-check-label" for="exampleRadiosq22">
                        Es un día para no olvidarnos, aprender de las lecciones del pasado y transmitir las enseñanzas a las nuevas generaciones. 
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input radiocheck7" type="radio" name="exampleRadios" id="exampleRadiosq23" value="7" [(ngModel)]="q2" (change)="onRadioChange($event, 7)">
                    <label class="form-check-label" for="exampleRadiosq23">
                        Reafirmar el compromiso de que Toyota está primero, y continuar con nuestros procesos que garantizan seguridad y calidad.  
                    </label>
                  </div>
                  <div *ngIf="q2Inicia">
                    <div class="correct" *ngIf="q2correcta">Respuesta correcta</div>
                    <div class="incorrect" *ngIf="!q2correcta">Respuesta incorrecta</div>
                  </div>
                  
            </div>           
            <button
            type="submit"  class="btn-send" (click)="submitForm()">Enviar</button> 
            <div class="clearfix"></div> 
        </div>
    </div>   
</div>